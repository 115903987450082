@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");

body {
  margin: 0;
  font-family: "Segoe UI", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: "#747474";
}

code {
  font-family: "Segoe UI", sans-serif !important;
}

.form-check-input:checked {
  background-color: #fc9200;
  border-color: #fc9200;
  color: "#747474";
}
