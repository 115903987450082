.inputID::placeholder {
  color: #fc9200;
  opacity: 1;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fc9200;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.col3 {
  flex: 0 0 auto;
  width: 32%;
  height: 60px;
  margin: 0px;
}

.col4 {
  flex: 0 0 auto;
  width: 30%;
}
.col45 {
  flex: 0 0 auto;
  width: 47%;
}

.containerss {
  height: 100vh;
  width: 100%;
}

input[type="range"]::-webkit-slider-thumb {
  background: orange !important;
}
.a .form-check-input:checked {
  background-color: #fc9200;
  border-color: #fc9200;
}

.textPrimary {
  color: #fc9200;
}
span {
  color: "#626262";
  font-weight: "600";
}
body.swal2-height-auto {
  height: auto !important;
  width: 100%;
}

.swal2-container.swal2-center > .swal2-popup {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  width: 270px;
}

.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #fc9200 !important;
  color: #fff;
  font-size: 1em;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: 0.8em 1em 0;
  color: inherit;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-html-container {
  z-index: 1;
  justify-content: center;
  margin: 1em 1.6em 0.3em;
  padding: 0;
  overflow: auto;
  color: inherit;
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
  word-break: break-word;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #fc9200 !important;
  color: #fff;
  font-size: 14px;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #6e7881;
  color: #fff;
  font-size: 14px;
}

.fa-stack {
  position: absolute;
  right: 20%;
  top: 15%;
  content: attr(data-count);
  font-size: 30%;
  padding: 0.6em;
  border-radius: 999px;
  line-height: 0.75em;
  color: white;
  color: $shopping-cart-red;
  text-align: center;
  min-width: 2em;
  font-weight: bold;
  background: white;
  border-style: solid;
}

.cardItem:hover {
  cursor: pointer;
  /* box-shadow: 0px 8px 15px 5px rgba(120, 0, 0, 0.2);
  transition: 0.3s ease;
  transform: scale(1);
  -webkit-transform: scale(1); */
}

.primary {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #fff;
  --bs-btn-bg: #fc9200;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.fontWeightLight {
  font-size: 1rem;
  font-weight: 300;
  margin-top: 1rem;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 14px;
    left: 14px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 29px;
    height: 29px;
    opacity: 0;
  }
}

.cursor {
  cursor: pointer;
}

.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.icon-button:hover {
  cursor: pointer;
}

.icon-button__badge {
  position: absolute;
  top: -15px;
  right: -10px;
  width: 24px;
  height: 22px;
  background: rgb(25, 135, 84);
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.badgeTop {
  top: -10px !important;
}

#inputID::placeholder {
  color: rgb(252, 146, 0);
  font-weight: bold;
}
/* .ribbon-wrapper {
  overflow: hidden;
  position: absolute;
  width: 100px;
  background-color: rgb(252, 146, 0);
  position: fixed;
  width: 104px;
  text-align: center;
  transform: rotate(313deg);
  left: -20px;
  top: auto;
  bottom: auto;
  color: #fff;
  z-index: 0;
  border-top-right-radius: 180px;
  border-top-left-radius: 180px;
  border-bottom-left-radius: 0;
} */
.tooo {
  position: relative;
}
.topleft {
  position: absolute;
  top: 8px;
  left: -27px;
  font-size: 16px;
  overflow: hidden;
  background-color: rgb(252, 146, 0);
  width: 95px;
  text-align: center;
  transform: rotate(317deg);
  color: #fff;
  z-index: 0;
  border-top-right-radius: 180px;
  border-top-left-radius: 180px;
  border-bottom-left-radius: 0;
}
/* .ribbon {
  background-color: rgb(252, 146, 0);
  position: absolute;
  width: 85px;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(319deg);
  left: -20px;

  color: #fff;
  z-index: 0;
} */

.fixed-header,
.fixed-footer {
  width: 100%;
  position: fixed;
}
.fixed-header {
  top: 0;
}
.fixed-footer {
  bottom: 0;
}

.salesForm {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.powerbyText {
  margin-bottom: 0px;
  margin-left: -85px;
  font-size: 12px;
}

.hrLine {
  margin: 0px auto;
  width: 150px;
  margin-bottom: -18px;
  margin-right: auto;
  margin-left: auto;
}
.react-pdf {
  position: relative;
  /* // top: -10px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 40px;
  left: 4px;
  width: 100%;
  height: 100%;
}

.imgTitle {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;

  position: absolute;

  bottom: 54px;
}
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #ffe8cc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #ffe8cc;
}
.MuiTouchRipple-root {
  display: none;
}
